import React from 'react';
import { useRouter } from 'next/router';
import InputButton from '../../../common/input-button/InputButton';
import useLanguage from '../../../../hooks/useLanguage';

function Tracking() {
  const [translation, getCurrentLanguage] = useLanguage();
  const language = getCurrentLanguage();
  const [trackingNumber, setTrackingNumber] = React.useState('');
  const router = useRouter();
  return (
    <InputButton
      inputPlaceHolder={translation(
        language,
        'hero.nav.trackOrder.inputPlaceholder'
      )}
      inputValue={trackingNumber}
      onChangeInput={(e) => {
        setTrackingNumber(e.target.value);
      }}
      onClickButton={(e) => {
        e.preventDefault();
        router.push(`/en/tracking/${trackingNumber}`);
      }}
      buttonTitle={translation(language, 'hero.nav.trackOrder.button')}
    />
  );
}

export default Tracking;
