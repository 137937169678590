import React from 'react';
import FaqAccordion from './FaqAccordion';
import MoreQuestions from './MoreQuestions';

function Faqs() {
  return (
    <div
      className="w-full items-center justify-center flex flex-col gap-6 md:gap-10 h-fit container py-16"
      key="faqs"
      id="faqs"
    >
      <div className="w-full py-4 flex flex-col justify-center items-center gap-10 md:w-2/3">
        <div className="w-full md:w-1/2 flex justify-center items-center flex-col gap-2">
          <h1 className="text-3xl text-center font-medium">
            Preguntas frecuentes
          </h1>
          <p className="text-gray-500">
            ¿Qué debo saber antes de pedir un envío?
          </p>
        </div>
        <FaqAccordion />
      </div>
      <MoreQuestions />
    </div>
  );
}

export default Faqs;
