import React from 'react';

function RightHeroDetailsIcon({
  className,
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 600 234"
      className={className}
      {...props}
    >
      <path
        d="M480 162.006L946.667 103.653V174.764L480 233.117V162.006Z"
        fill="#D3D8E1"
      />
      <path
        d="M0 151.787L680 66.4443V137.555L0 222.898V151.787Z"
        fill="#AEB5C8"
      />
      <path
        d="M177.777 58.3522L644.444 0V71.1111L177.777 129.463V58.3522Z"
        fill="#405D7D"
      />
    </svg>
  );
}

export default RightHeroDetailsIcon;
