import Link from 'next/link';

import { useRouter } from 'next/router';
import { useMutation } from '@apollo/client';
import { signOut } from 'next-auth/react';
import { MainNav } from '../main-nav';
import Footer from '../Footer';
import { Button } from '../ui/button';
import { siteConfig } from '../../config/site';
import { mainConfig } from '../../config/main';
// import { docsConfig } from '../../config/docs';
// import { DocsSidebarNav } from '../sidebar-nav';
import { useUser } from '../../hooks';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu';
import { Avatar, AvatarFallback, AvatarImage } from '../ui/avatar';
import { SIGN_OUT } from '../../graphql/mutation';
import { ModeToggle } from './ModeToggle';

interface LandingLayoutProps {
  children: React.ReactNode;
}

export default function LandingLayout({ children }: LandingLayoutProps) {
  const [user, setUser] = useUser();
  const router = useRouter();

  return (
    <div className="flex bg-white dark:bg-primary-600 min-h-screen h-fit flex-col">
      <header className="sticky top-0 z-40 w-full border-b border-b-slate-200 bg-white dark:bg-primary-800">
        <div className="container flex h-16 items-center space-x-4 sm:justify-between sm:space-x-0">
          <MainNav items={mainConfig.mainNav}>
            {/* <DocsSidebarNav items={docsConfig.sidebarNav} /> */}
          </MainNav>
          <div className="flex flex-1 items-center space-x-4 sm:justify-end">
            <div className="flex-1 sm:grow-0">{/* <DocsSearch /> */}</div>
            <nav className="flex space-x-4">
              {user ? (
                <DropdownMenu>
                  <DropdownMenuTrigger>
                    <Avatar
                      onClick={(e) => {
                        e.preventDefault();
                        router.push('/app');
                      }}
                      className="cursor-pointer border"
                    >
                      <AvatarImage
                        src={
                          user?.profilePicture
                            ? user?.profilePicture
                            : '/avatar-placeholder.png'
                        }
                      />
                      <AvatarFallback>
                        <AvatarImage src="/avatar-placeholder.png" />
                      </AvatarFallback>
                    </Avatar>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent>
                    <DropdownMenuLabel>Mi cuenta</DropdownMenuLabel>
                    <DropdownMenuSeparator />
                    <DropdownMenuItem
                      className="cursor-pointer"
                      onClick={(e) => {
                        e.preventDefault();
                        router.push('/app');
                      }}
                    >
                      Home
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      className="cursor-pointer"
                      onClick={(e) => {
                        e.preventDefault();
                        router.push('/app/orders');
                      }}
                    >
                      Mis envíos
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      className="cursor-pointer"
                      onClick={(e) => {
                        e.preventDefault();
                        router.push('/app/freights');
                      }}
                    >
                      Mis fletes
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      className="cursor-pointer"
                      onClick={(e) => {
                        e.preventDefault();
                        router.push('/app/payments');
                      }}
                    >
                      Mis pagos
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      className="cursor-pointer"
                      onClick={(e) => {
                        e.preventDefault();
                        router.push('/app/shipment');
                      }}
                    >
                      Hacer envío
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      className="cursor-pointer"
                      onClick={(e) => {
                        e.preventDefault();
                        router.push('/app/calculator');
                      }}
                    >
                      Calcular
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      className="cursor-pointer"
                      onClick={(e) => {
                        e.preventDefault();
                        router.push('/app/settings');
                      }}
                    >
                      Perfil
                    </DropdownMenuItem>
                    <DropdownMenuSeparator />
                    <DropdownMenuItem
                      className="cursor-pointer"
                      onClick={(e) => {
                        e.preventDefault();
                        signOut().then(() => {
                          // clean localstorage user
                          localStorage.removeItem('currentClientUser');
                          window.location.href = '/';
                        });
                      }}
                    >
                      Cerrar sesión
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              ) : (
                // <Button variant="outline">
                //   <Link href="/app/test">App</Link>
                // </Button>
                <>
                  <Button variant="outline" className="">
                    <Link href="/sign-in">Ingresa</Link>
                  </Button>
                  <Button className="">
                    <Link href="/sign-up">Regístrate</Link>
                  </Button>
                  {/* <ModeToggle /> */}
                </>
              )}
            </nav>
          </div>
        </div>
      </header>
      <div className="flex-1 h-full w-full border-green-200">{children}</div>
    </div>
  );
}
