export const ourServicesSea = {
  title: 'Marítimo',
  description:
    'Enviar tus paquetes marítimos nunca fue tan fácil. Contamos con una amplia red de agentes de carga en todo el mundo',
  steps: ['Asesoría', 'Calculadora', 'Acompañamiento', 'Seguro', 'Transporte'],
};

export const ourServicesAir = {
  title: 'Aéreo',
  description:
    'Te llevamos de la mano para que puedas enviar tus paquetes aéreos de la manera más rápida y segura. Contamos con una amplia red de agentes de carga en todo el mundo.',
  steps: ['Asesoría', 'Calculadora', 'Acompañamiento', 'Seguro', 'Transporte'],
};
