import React from 'react';
import { animateScroll, Link as ScrollLink } from 'react-scroll';
import Link from 'next/link';
import { mainConfig } from '../config/main';
import { cn } from '../lib/utils';

function Footer() {
  return (
    <footer className="w-full gap-8 md:gap-4 h-fit container py-8 items-start md:items-center justify-between flex flex-col md:flex-row">
      <button
        className="flex items-center space-x-2"
        onClick={() =>
          animateScroll.scrollToTop({
            duration: 500,
            smooth: 'easeInOutQuart',
          })
        }
        type="button"
      >
        <img
          src="/media/logos/horizontal.png"
          alt="Logo"
          className="h-14 w-32"
        />
      </button>
      <div className="flex flex-wrap gap-3  md:gap-0 w-full md:justify-evenly">
        {mainConfig?.mainNav.map((item, index) => (
          <ScrollLink
            key={index}
            to={item.key} // Use an empty string if the link is disabled
            smooth // Enable smooth scrolling
            duration={500} // Set the duration of the scroll animation to 500ms
            className={cn(
              'flex items-center cursor-pointer text-lg font-semibold text-slate-600 sm:text-sm',
              item.disabled && 'cursor-not-allowed opacity-80'
            )}
            offset={-60}
          >
            {item.title}
          </ScrollLink>
        ))}
      </div>
      <Link href="/privacy">
        <p className="text-lg font-semibold text-slate-600 sm:text-sm">
          Privacidad
        </p>
      </Link>
      <div className="flex  md:w-80 items-start md:items-center md:justify-between justify-center">
        <div className="w-full md:px-4">
          <div className="text-sm w-full text-gray-500 font-semibold py-1">
            Copyright © {new Date().getFullYear()}{' '}
            <span className="text-gray-500 hover:text-gray-600 text-sm font-semibold py-1">
              MOGOS
            </span>{' '}
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
