import React from 'react';
import Link from 'next/link';
import { Button } from '../../ui/button';

export default function MoreQuestions() {
  return (
    <div className="w-full bg-gray-50 rounded-lg justify-center items-center h-fit py-8 flex flex-col gap-8">
      <img
        src="/media/home/faqs/faqs-avatars.png"
        alt="More questions"
        className="w-28 h-12"
      />
      <h1 className="text-2xl font-medium">¿Tienes más preguntas?</h1>
      <Link
        className="text-lg"
        href="https://api.whatsapp.com/send?phone=584241856592&text=Hola%20Mogos%20Group%20me%20gustar%C3%ADa%20saber%20m%C3%A1s%20informaci%C3%B3n%20sobre%20sus%20servicios"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button>¡Contáctanos!</Button>
      </Link>
    </div>
  );
}
