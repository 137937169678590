export const alliesInfo = [
  {
    name: 'ups',
    imgSrc: '/media/home/allies/ups.png',
    url: 'https://www.ups.com/',
  },
  {
    name: 'dhl',
    imgSrc: '/media/home/allies/dhl.png',
    url: 'https://www.dhl.com/',
  },
  {
    name: 'zim',
    imgSrc: '/media/home/allies/zim.png',
    url: 'https://www.zim.com/',
  },
  {
    name: 'hapag-lloyd',
    imgSrc: '/media/home/allies/hapag.png',
    url: 'https://www.hapag-lloyd.com/',
  },
  {
    name: 'cmacgm',
    imgSrc: '/media/home/allies/cma.png',
    url: 'https://www.cma-cgm.com/',
  },
];
