import React from 'react';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '../../ui/accordion';

function FaqAccordion() {
  return (
    <Accordion type="single" collapsible className="w-full">
      <AccordionItem value="item-1">
        <AccordionTrigger>¿Cómo calculo mi envío?</AccordionTrigger>
        <AccordionContent>
          Ingresa los datos de tu envío en la pestaña "Calculadora" que
          encuentras al principio de nuestra página web.
        </AccordionContent>
      </AccordionItem>
      <AccordionItem value="item-2">
        <AccordionTrigger>¿Cómo me registro?</AccordionTrigger>
        <AccordionContent>
          Haz click en el botón "Regístrate". Sólo necesitas tu correo y tu
          contraseña.
        </AccordionContent>
      </AccordionItem>
      <AccordionItem value="item-3">
        <AccordionTrigger>
          ¿Necesito una cuenta para poder hacer tracking de mi envío?
        </AccordionTrigger>
        <AccordionContent>Es lo recomendado.</AccordionContent>
      </AccordionItem>
      <AccordionItem value="item-4">
        <AccordionTrigger>¿Qué métodos de pago tienen?</AccordionTrigger>
        <AccordionContent>
          Pago móvil, efectivo, Zelle, Binance y tarjeta internacional.
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
}

export default FaqAccordion;
