import React from 'react';

function LeftHeroDetailsIcon({
  className,
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 387 222"
      className={className}
      {...props}
    >
      <path
        d="M-80 58.4625L386.667 0.110352V71.2215L-80 129.574V58.4625Z"
        fill="#D3D8E1"
      />
      <path
        d="M-248.889 150.672L217.778 92.3198V163.431L-248.889 221.783V150.672Z"
        fill="#405D7D"
      />
    </svg>
  );
}

export default LeftHeroDetailsIcon;
