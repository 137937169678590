/* eslint-disable no-nested-ternary */
import React from 'react';
import useLanguage from '../../../../../hooks/useLanguage';
import Button from '../../../../common/button/Button';

import Address from './Address';
import PersonalInformation from './PersonalInformation';
import References from './References';
import ShipmentInformation from './ShipmentInformation';
import { shippingReducer, ACTIONS, initialState } from './shippingReducer';
import { useUser } from '../../../../../hooks';
import { DocumentModel } from '../../../../../models';

function Shipment() {
  const center = { lat: 37.7749, lng: -122.4194 };
  const [user] = useUser();

  const [state, dispatch] = React.useReducer(shippingReducer, initialState);
  const [images, setImages] = React.useState<DocumentModel[]>([]);
  const updateURLs = React.useCallback(setImages, [setImages]);
  const [packingLists, setPackingLists] = React.useState<DocumentModel[]>([]);
  const updatePackingLists = React.useCallback(setPackingLists, [
    setPackingLists,
  ]);
  const [invoices, setInvoices] = React.useState<DocumentModel[]>([]);
  const updateInvoices = React.useCallback(setInvoices, [setInvoices]);

  const buttonOptions = [
    // {
    //   label: 'Información personal',
    //   value: 'personalInformation',
    // },
    {
      label: 'Dirección',
      value: 'address',
    },
    {
      label: 'Información del envío',
      value: 'shipmentInformation',
    },
  ];

  // Solo agregar la opción de 'Referencias' si el tipo de envío no es 'air'
  if (state.shippingType !== 'air') {
    buttonOptions.push({
      label: 'Referencias',
      value: 'references',
    });
  }

  const [selected, setSelected] = React.useState(buttonOptions[0].value);

  React.useEffect(() => {
    if (user) {
      dispatch({
        type: ACTIONS.POSTAL_CODE,
        payload: user?.addresses[0]?.postalCode,
      });

      dispatch({
        type: ACTIONS.RECEPTION_STATE,
        payload: user?.addresses[0]?.state,
      });

      const address = `${user?.addresses[0]?.firstLine} ${user?.addresses[0]?.secondLine} ${user?.addresses[0]?.city} ${user?.addresses[0]?.state} ${user?.addresses[0]?.postalCode}`;
      dispatch({
        type: ACTIONS.ADDRESS,
        payload: address,
      });
    }
  }, [user]);

  return (
    <div className="w-full h-auto md:max-h-screen flex flex-col items-center justify-center bg-font-white rounded-md">
      <div className="w-full flex flex-col h-full justify-center p-2 ">
        <div className="w-full py-2 px-1 flex">
          <div className="w-full md:w-fit flex-col md:flex-row flex justify-start">
            {buttonOptions.map(({ label, value }, index) =>
              !user ? null : (
                <Button
                  className="w-full  md:w-fit "
                  buttonType="sectionNavbar"
                  key={value}
                  onClick={(e) => {
                    e.preventDefault();
                    setSelected(value);
                  }}
                  active={value === selected}
                >
                  {label}
                </Button>
              )
            )}
          </div>
          <div className="flex flex-1 border-b border-neutral-300" />
        </div>
        <form className="flex py-2 flex-col transition-all transition-height ease-in-out duration-600">
          {selected === 'personalInformation' ? (
            <PersonalInformation
              state={state}
              dispatch={dispatch}
              setSelected={setSelected}
            />
          ) : selected === 'address' ? (
            <Address
              state={state}
              dispatch={dispatch}
              setSelected={setSelected}
            />
          ) : selected === 'shipmentInformation' ? (
            <ShipmentInformation
              state={state}
              dispatch={dispatch}
              setSelected={setSelected}
            />
          ) : selected === 'references' ? (
            <References
              state={state}
              dispatch={dispatch}
              setSelected={setSelected}
              updateURLs={updateURLs}
              images={images}
              updatePackingLists={updatePackingLists}
              packingLists={packingLists}
              updateInvoices={updateInvoices}
              invoices={invoices}
            />
          ) : null}
        </form>
      </div>
    </div>
  );
}

export default Shipment;
