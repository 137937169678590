import React from 'react';
import { motion } from 'framer-motion';
import { Fade } from 'react-awesome-reveal';
import Hero from './landing/hero/Hero';
import EmailLogIn from './landing/email-login/EmailLogIn';
import HowItWorks from './home/our-services/OurServices';
import Faqs from './landing/faqs/Faqs';
import Footer from './Footer';
import { useUser } from '../hooks';
import Allies from './home/allies/Allies';
import ShipWithUs from './home/ship-with-us/ShipWithUs';
import RandomImage from './home/RandomImage';
import PaymentMethods from './home/payment-methods/PaymentMethods';
import OurServices from './home/our-services/OurServices';

function Home() {
  const [user, setUser] = useUser();
  return (
    <main>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <Hero />
        <Fade cascade triggerOnce duration={300}>
          <Allies />
          <ShipWithUs />
          <RandomImage />
          <OurServices />
          <PaymentMethods />
          <Faqs />
          <Footer />
        </Fade>
      </motion.div>
    </main>
  );
}

export default Home;
