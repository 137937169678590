import React from 'react';
import { Button } from '../../ui/button';

function ShipWithUs() {
  return (
    <div
      className="w-full container py-16  flex items-center flex-col md:flex-row relative "
      style={{ height: 'calc(100vh - 65px)' }}
    >
      <div className=" w-full  flex flex-col  md:w-1/2 h-full justify-center  items-center bg-white gap-4 ">
        <div className="w-full  md:w-2/3 flex flex-col gap-4">
          <h1 className="">
            <span className=" text-2xl md:text-3xl text-start font-medium text-black">
              Haz tus envíos con MOGOS
            </span>
          </h1>
          <p className="text-start text-neutral-400">
            {' '}
            Atención personalizada{' '}
          </p>
          <div className="flex flex-row md:flex-row gap-4">
            <Button variant="outline">
              <span>Calcula tu envío</span>
            </Button>
            <Button>
              <span>Empieza</span>
            </Button>
          </div>
        </div>
      </div>
      <div className="w-1/2 h-full hidden md:block ">
        <img
          src="/media/img/hero/hero-bg-1.jpg"
          alt=""
          className=" object-cover w-full h-full  "
        />
      </div>
    </div>
  );
}

export default ShipWithUs;
