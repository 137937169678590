import React from 'react';
import RoundedCheckIcon from '../../icons/RoundedCheckIcon';
import { Button } from '../../ui/button';
import QuestionCard from '../../common/QuestionCard';
import OurServicesCard from '../../common/QuestionCard';
import { ourServicesAir, ourServicesSea } from '../../../lib/ourServices';

function OurServices() {
  const steps = [
    'Responde todas las preguntas en escala del 1 al 5',
    'Regresa si deseas corregir alguna pregunta',
    'Obtén tu reporte de resultados',
  ];

  return (
    <div
      className=" container w-full py-4 bg-white flex flex-col md:flex-row md:items-center h-fit gap-8 md:gap-0 h-screen-16"
      key="how-it-works"
      id="how-it-works"
      style={
        window.innerWidth > 760
          ? { height: 'calc(100vh - 16px)' }
          : { height: 'auto' }
      }
    >
      <div className="w-full md:w-1/2 justify-center items-start md:pl-20 flex flex-col gap-8 ">
        <div className="flex flex-col w-full justify-start itemems-center">
          <h1 className="text-3xl font-semibold text-start">
            Nuetros servicios
          </h1>
          <p className="text-gray-500 text-start">
            Pedir de China a Venezuela nunca había sido tan fácil.
          </p>
        </div>
        <div className="flex flex-col h-60 w-70 gap-2">
          <img
            src="/media/home/our-services/our-services.png"
            alt=""
            className="w-full object-fill h-full"
          />
        </div>
      </div>
      <div className="w-full md:w-1/2 flex md:flex-row flex-col gap-4">
        <OurServicesCard
          title={ourServicesSea?.title}
          description={ourServicesSea?.description}
          steps={ourServicesSea?.steps}
        />
        <OurServicesCard
          title={ourServicesAir?.title}
          description={ourServicesAir?.description}
          steps={ourServicesAir?.steps}
        />
      </div>
    </div>
  );
}

export default OurServices;
