/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_ADVERTISEMENTS } from '../graphql/queries';
import { Advertisement } from '../models';
import LandingLayout from '../components/layout/LandingLayout';
import Home from '../components/Home';

export default function HomePage() {
  const { data, loading } = useQuery<{ advertisements: Advertisement[] }>(
    GET_ADVERTISEMENTS,
    {
      fetchPolicy: 'network-only',
      variables: {
        sort: '_ID_DESC',
      },
    }
  );
  const [advertisements, setAdvertisements] = React.useState(
    [] as Advertisement[]
  );

  React.useEffect(() => {
    if (data?.advertisements) {
      setAdvertisements(data?.advertisements);
    }
  }, [data?.advertisements, loading]);

  return (
    <LandingLayout>
      <Home />
    </LandingLayout>
  );
}
