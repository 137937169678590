export const paymentMethodsInfo = [
  {
    name: 'pagoMovil',
    imgSrc: '/media/home/payment-methods/pago-movil.png',
  },
  {
    name: 'cash',
    imgSrc: '/media/home/payment-methods/cash.png',
  },
  {
    name: 'mastercard',
    imgSrc: '/media/home/payment-methods/mastercard.png',
  },
  {
    name: 'visa',
    imgSrc: '/media/home/payment-methods/visa.png',
  },
  {
    name: 'zelle',
    imgSrc: '/media/home/payment-methods/zelle.png',
  },
  {
    name: 'binance',
    imgSrc: '/media/home/payment-methods/binance.png',
  },
];
