import React from 'react';
import Button from '../../../common/button/HeroButton';
import useLanguage from '../../../../hooks/useLanguage';
import Tracking from '../tracking/Tracking';
import Calculator from './calculator/Calculator';
import Shipping from './shipping/Shipping';

function Navigation() {
  const [translation, getCurrentLanguage] = useLanguage();
  const language = getCurrentLanguage();
  // useRef
  const ref = React.useRef<HTMLDivElement>(null);
  const buttonOptions = [
    {
      label: translation(language, 'hero.nav.trackOrder.title'),
      value: 'trackOrder',
    },
    {
      label: translation(language, 'hero.nav.calculator'),
      value: 'calculator',
    },
    // {
    //   label: translation(language, 'hero.nav.shipping.title'),
    //   value: 'shipping',
    // },
    // {
    //   label: translation(language, 'hero.nav.services'),
    //   value: 'services',
    // },
  ];
  const [height, setHeight] = React.useState(ref?.current?.clientHeight ?? 22);
  const [selected, setSelected] = React.useState(buttonOptions[0].value);

  React.useEffect(() => {
    if (ref?.current) {
      setHeight(ref?.current?.clientHeight);
    }
  }, [ref?.current?.clientHeight, ref?.current?.style?.height]);

  return (
    <div
      className="w-full px-8 py-4 flex flex-col overflow-auto sm:w-[70vw] md:w-[60vw] transition-height ease-in-out duration-600 bg-primary-600 bg-opacity-30 p-2 mt-4 rounded-lg"
      // className={`w-2/5 flex flex-col max-h-screen  transition-height ease-in-out duration-600 bg-primary-600 bg-opacity-30 p-8 rounded-lg ${
      //   height ? `h-full` : 'h-full'
      // }`}
    >
      <div className="w-full flex flex-col md:flex-row gap-2">
        {buttonOptions.map(({ label, value }) => (
          <Button
            className="  "
            buttonType="hero-button"
            key={value}
            onClick={(e) => {
              e.preventDefault();
              setSelected(value);
            }}
            selected={value === selected}
          >
            <span className=" font-medium text-base ">{label}</span>
          </Button>
        ))}
      </div>
      <div
        className="flex mt-4 flex-col transition-all transition-height ease-in-out duration-600 overflow-auto "
        ref={ref}
      >
        {selected === 'trackOrder' && <Tracking />}
        {selected === 'calculator' && <Calculator />}
        {selected === 'shipping' && <Shipping />}
        {selected === 'services' && <div>here 3</div>}
      </div>
    </div>
  );
}

export default Navigation;
