/* eslint-disable react/jsx-pascal-case */

'use client';

import * as React from 'react';
import Link from 'next/link';
import { animateScroll, Link as ScrollLink } from 'react-scroll';

import { cn } from '../lib/utils';
import { Icons } from './Icons';
import { MobileNav } from './mobile-nav';
import { MainNavItem } from '../models';

interface MainNavProps {
  items?: MainNavItem[];
  children?: React.ReactNode;
}

export function MainNav({ items, children }: MainNavProps) {
  const [showMobileMenu, setShowMobileMenu] = React.useState<boolean>(false);

  return (
    <div className="flex gap-6 md:gap-10">
      <Link
        href="/"
        className="hidden items-center space-x-2 md:flex"
        onClick={() =>
          animateScroll.scrollToTop({
            duration: 500,
            smooth: 'easeInOutQuart',
          })
        }
      >
        <img
          src="/media/logos/horizontal.png"
          alt="Logo"
          className="h-13 w-28 md:h-13 md:w-28"
        />
      </Link>
      {items?.length ? (
        <nav className="hidden gap-6 md:flex">
          {items?.map((item, index) => (
            <ScrollLink
              key={index}
              to={item.key} // Use an empty string if the link is disabled
              smooth // Enable smooth scrolling
              duration={500} // Set the duration of the scroll animation to 500ms
              className={cn(
                'flex items-center cursor-pointer text-lg font-semibold text-slate-600 sm:text-sm',
                item.disabled && 'cursor-not-allowed opacity-80'
              )}
              offset={-60}
            >
              {item.title}
            </ScrollLink>
          ))}
        </nav>
      ) : null}
      <button
        className="flex items-center space-x-2 md:hidden"
        onClick={() => setShowMobileMenu(!showMobileMenu)}
        type="button"
      >
        {showMobileMenu ? <Icons.close /> : <Icons.logo />}
        <img
          src="/media/logos/horizontal.png"
          alt="Logo"
          className="h-11 w-20 md:h-12 md:w-28"
        />
      </button>
      {showMobileMenu && items && (
        <MobileNav items={items} setShowMobileMenu={setShowMobileMenu}>
          {children}{' '}
        </MobileNav>
      )}
    </div>
  );
}
