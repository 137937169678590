import React from 'react';
import { ArrowRight } from 'lucide-react';
import { useRouter } from 'next/router';
import { Badge } from '../../ui/badge';
import { Button } from '../../ui/button';
import Navigation from '../../home/hero/navigation/Navigation';
import LeftHeroDetailsIcon from '../../icons/LeftHeroDetails';
import RightHeroDetailsIcon from '../../icons/RightHeroDetailsIcon';

function Hero() {
  const router = useRouter();
  return (
    <div
      className="w-full -z-0 flex overflow-hidden flex-col md:flex-row relative bg-neutral-100 h-auto sm:h-[calc(100vh-68px)] items-center justify-center "
      id="hero"
      key="hero"
    >
      <div className=" w-full py-4  md:py-0 z-100 flex flex-col items-center justify-center ">
        <div className="w-[95vw]   gap-8 flex flex-col items-center justify-center h-full">
          <Badge
            className="w-fit flex p-1 px-2 gap-2 items-center"
            onClick={() => router.push('/sign-in')}
          >
            <span className="bg-primary-none border border-primary-600 -ml-1 py-1 px-2 rounded-full">
              ¿Quieres enviar desde china?
            </span>
            <span className="flex items-center">Hazlo con MOGOS</span>
            <ArrowRight className="w-4 h-4 text-primary-600" />
          </Badge>
          {/* <h1 className="text-center">
            <span className=" text-center text-xl font-medium text-primary-600">
              X años de experiencia Y cantidad de pedidos
            </span>
          </h1> */}
          <Navigation />
          <div className="flex flex-col md:flex-row gap-4">
            <Button
              variant="outline"
              onClick={
                // scroll to how it works
                () => {
                  const element = document.getElementById('how-it-works');
                  element?.scrollIntoView({ behavior: 'smooth' });
                }
              }
            >
              <span>¿Cómo funciona?</span>
            </Button>
            <Button
              onClick={
                // push to sign-in
                () => router.push('/sign-in')
              }
            >
              <span>¡Comienza ahora!</span>
            </Button>
          </div>
        </div>
      </div>
      <div className="absolute w-full h-full flex ">
        <div className="relative w-full h-full flex ">
          <div className="w-1/3 absolute h-full left-0 flex items-center justify-start">
            <LeftHeroDetailsIcon className="w-full h-60" />
          </div>
          <div className="w-1/3 h-full absolute right-0 flex items-center  justify-start">
            <RightHeroDetailsIcon className="w-full h-80 absolute top-28 " />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
