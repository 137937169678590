/* eslint-disable react/button-has-type */
import React from 'react';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  withMargin?: boolean;
  color?: string;
  withIcon?: string;
  iconClassName?: string;
  outline?: boolean;
  buttonType?: string;
  active?: boolean;
  selected?: boolean;
}

export default function Button({
  children,
  type,
  disabled,
  className,
  withMargin = true,
  color = 'primary',
  outline = false,
  withIcon,
  iconClassName,
  buttonType,
  active,
  selected,
  ...rest
}: ButtonProps) {
  const [showBorder, setShowBorder] = React.useState(false);
  const handleMouseEnter = () => {
    setShowBorder(true);
  };

  const handleMouseLeave = () => {
    setShowBorder(false);
  };
  const classes = `flex gap-2 justify-center items-center h-full  py-2 px-2 text-sm text-lemon rounded-md focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed ${className} ${
    !withMargin ? '' : 'mr-2'
  } `;
  let bgClass;
  let textClass;
  let borderClass;

  switch (color) {
    case 'success':
      bgClass = outline ? 'bg-transparent' : 'bg-success-300';
      textClass = outline ? 'text-success-300' : 'text-font-white';
      borderClass = outline ? 'border-success-300' : 'border-transparent';
      if (outline) {
        bgClass += ' hover:bg-success-200';
        textClass += ' hover:text-success-200';
        borderClass += ' hover:border-success-200';
      } else {
        bgClass +=
          ' hover:bg-success-200 active:bg-success-400 active:shadow-inner';
      }
      break;
    case 'neutral':
      bgClass = outline ? 'bg-transparent' : 'bg-neutral-400';
      textClass = outline ? 'text-neutral-400' : 'text-font-white';
      borderClass = outline ? 'border-neutral-400' : 'border-transparent';
      if (outline) {
        bgClass += ' hover:bg-neutral-500';
        textClass += ' hover:text-neutral-600';
        borderClass += ' hover:border-neutral-600';
      } else {
        bgClass +=
          ' hover:bg-neutral-500 active:bg-neutral-600 active:shadow-inner';
      }
      break;
    case 'danger':
      bgClass = outline ? 'bg-transparent' : 'bg-danger-300';
      textClass = outline ? 'text-danger-300' : 'text-font-white';
      borderClass = outline ? 'border-danger-300' : 'border-transparent';
      if (outline) {
        bgClass += ' hover:bg-danger-200';
        textClass += ' hover:text-danger-200';
        borderClass += ' hover:border-danger-200';
      } else {
        bgClass +=
          ' hover:bg-danger-200 active:bg-danger-400 active:shadow-inner';
      }
      break;
    case 'warning':
      bgClass = outline ? 'bg-transparent' : 'bg-warning-300';
      textClass = outline ? 'text-warning-300' : 'text-font-white';
      borderClass = outline ? 'border-warning-300' : 'border-transparent';
      if (outline) {
        bgClass += ' hover:bg-warning-200';
        textClass += ' hover:text-warning-200';
        borderClass += ' hover:border-warning-200';
      } else {
        bgClass +=
          ' hover:bg-warning-200 active:bg-warning-400 active:shadow-inner';
      }
      break;
    case 'none':
      bgClass = 'bg-transparent';
      textClass = 'text-secondary-500 hover:text-secondary-300';
      borderClass = 'border-transparent';
      break;
    case 'white':
      bgClass = 'bg-transparent';
      textClass = 'text-font-white hover:text-font-white';
      borderClass = 'outline-font-white outline';
      break;
    default:
      bgClass = outline ? 'bg-transparent' : 'bg-primary-600';
      textClass = outline ? 'text-primary-300' : 'text-font-white';
      borderClass = outline ? 'border-primary-300' : 'border-transparent';
      if (outline) {
        bgClass += ' hover:bg-primary-200';
        textClass += ' hover:text-primary-200';
        borderClass += ' hover:border-primary-200';
      } else {
        bgClass +=
          ' hover:bg-primary-500 active:bg-primary-700 active:shadow-inner';
      }
      break;
  }

  let content = children;
  if (withIcon) {
    content = <div>{children}</div>;
  }

  let button;
  switch (buttonType) {
    case 'hero-button':
      button = (
        <div
          className="flex flex-col w-full"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <button
            type={type}
            className={` text-font-white hover:border-b-primary-200 cursor-pointer active:text-font-white active:border-b-primary-200 active:shadow-inner px-2 py-2 h-full w-full text-xsm ${className} ${
              selected ? 'border-b-primary-200 border-b-2' : ''
            } `}
            disabled={disabled}
            {...rest}
          >
            {content}
          </button>
          <div
            className={` transform transition-width duration-500 border-b-2 border-primary-200  ease-in-out   ${
              showBorder ? 'w-full ' : 'w-0'
            } ${selected ? 'hidden' : ''} `}
          />
        </div>
      );
      break;
    default:
      button = (
        <button
          type={type}
          className={`${classes} ${bgClass} ${borderClass} ${textClass}`}
          disabled={disabled}
          {...rest}
        >
          {content}
        </button>
      );
      break;
  }

  return button;
}
