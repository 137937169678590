import React from 'react';
import { Input } from '../input/Input';
import Button from '../button/Button';

interface InputButtonProps {
  inputValue?: any;
  inputPlaceHolder?: string;
  onChangeInput?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClickButton?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  buttonTitle?: string;
}

function InputButton({
  inputPlaceHolder,
  inputValue,
  onChangeInput,
  onClickButton,
  buttonTitle,
}: InputButtonProps) {
  return (
    <div className=" py-1 pl-2 w-full flex gap-2 rounded-md bg-font-white items-center justify-start">
      <Input
        withIcon="car"
        className="bg-font-white text-sm md:text-base "
        placeholder={inputPlaceHolder}
        divClassName="bg-font-white "
        value={inputValue}
        onChange={onChangeInput}
      />
      <Button onClick={onClickButton} className=" h-fit py-1 mr-2 ">
        {buttonTitle}
      </Button>
    </div>
  );
}

export default InputButton;
