import React from 'react';

function RandomImage() {
  return (
    <div className="container my-8 w-full h-60 rounded-md ">
      <img
        src="/media/home/random-image.png"
        alt=""
        className=" object-cover w-full h-full rounded-lg  "
      />
    </div>
  );
}

export default RandomImage;
