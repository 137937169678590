import React from 'react';
import { Card } from '../ui/card';
import RoundedCheckWithBackgroundIcon from '../icons/RoundedCheckWithBackgroundIcon';

interface OurServicesCardProps {
  title: string;
  description: string;
  steps: string[];
}

function OurServicesCard({ title, description, steps }: OurServicesCardProps) {
  return (
    <Card className="flex border-gray-100 md:w-2/3 flex-col gap-5 items-center justify-center container py-8 md:h-4/5 ">
      {/* <OrangeOurServicesIcon className="w-16 h-16 text-primary-600" /> */}
      <h1>
        <span className="text-2xl font-semibold text-primary-600">{title}</span>
      </h1>
      <p className="text-gray-500 text-justify">{description}</p>
      <div className="flex flex-col w-full gap-2">
        {steps.map((step, index) => (
          <div
            key={index}
            className={` flex gap-4 rounded-full w-full items-center px-2 py-1 ${
              index === 5 ? ' bg-success-100 ' : 'bg-gray-25'
            }`}
          >
            <RoundedCheckWithBackgroundIcon className="w-5 h-5 text-primary-600" />
            <span className="text-gray-500">{step}</span>
          </div>
        ))}
      </div>
    </Card>
  );
}

export default OurServicesCard;
