import React from 'react';
import { paymentMethodsInfo } from '../../../lib/paymentMethodsInfo';

function PaymentMethods() {
  return (
    <div
      className="bg-gray-800 w-full px-8 py-16 flex flex-col justify-center items-center gap-14"
      id="payment-methods"
      key="payment-methods"
    >
      <h1 className="text-white text-2xl md:text-4xl font-medium">
        Nuestros métodos de pago
      </h1>
      <div className="grid w-full grid-cols-2 space-y-4 sm:grid-cols-3 lg:flex lg:justify-evenly">
        {paymentMethodsInfo.map((paymentMethod) => (
          <div className="flex flex-col items-center  md:gap-0 gap-4 justify-center">
            <img
              src={paymentMethod.imgSrc}
              alt={paymentMethod.name}
              className="w-fit h-10 md:h-14 p-1 md:p-0"
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default PaymentMethods;
