import React from 'react';
import { alliesInfo } from '../../../lib/alliesInfo';

function Allies() {
  return (
    <div className="w-full p-8">
      <div className=" w-full bg-neutral-100 rounded-md flex flex-col items-center justify-center text-primary-700 py-8 gap-14  ">
        <p className="font-medium">Nuestros aliados</p>
        <div className=" w-full flex flex-wrap justify-evenly items-center">
          {alliesInfo.map((ally) => (
            <div className="flex flex-col items-center  md:gap-0 gap-4 justify-center">
              <img
                src={ally.imgSrc}
                alt={ally.name}
                className="w-fit h-10 md:h-14 p-1 md:p-0"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Allies;
