import React from 'react';

function RoundedCheckWithBackgroundIcon({
  className,
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 20 20"
      className={className}
      {...props}
    >
      <rect width="20" height="20" rx="10" fill="#D1FADF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.247 6.15837L8.28033 11.9167L6.69699 10.225C6.40533 9.95004 5.94699 9.93337 5.61366 10.1667C5.28866 10.4084 5.19699 10.8334 5.39699 11.175L7.27199 14.225C7.45533 14.5084 7.77199 14.6834 8.13033 14.6834C8.47199 14.6834 8.79699 14.5084 8.98033 14.225C9.28033 13.8334 15.0053 7.00837 15.0053 7.00837C15.7553 6.2417 14.847 5.5667 14.247 6.15004V6.15837Z"
        fill="#12B76A"
      />
    </svg>
  );
}

export default RoundedCheckWithBackgroundIcon;
